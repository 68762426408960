import { useEffect, useState, FormEvent, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import AuthContext from "../../context/AuthContext";

type Thread = {
  id: number;
  title: string;
  content: string;
  username: string;
  created_at: string;
};

type Reply = {
  id: number;
  content: string;
  username: string;
  created_at: string;
};

const ThreadPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [thread, setThread] = useState<Thread | null>(null);
  const [replies, setReplies] = useState<Reply[]>([]);
  const [totalReplies, setTotalReplies] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;

  const [editingReplyId, setEditingReplyId] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState("");
  const [replyContent, setReplyContent] = useState("");
  const [status, setStatus] = useState<"idle" | "submitting" | "error">("idle");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;

    const fetchThreadAndReplies = async () => {
      try {
        const threadRes = await fetch(`/api/forums/threads/${id}`);
        if (!threadRes.ok) throw new Error("Thread not found");
        const threadData = await threadRes.json();

        const repliesRes = await fetch(`/api/forums/threads/${id}/replies?page=${page}&limit=${limit}`);
        const repliesData = await repliesRes.json();

        setThread(threadData.thread);
        setReplies(repliesData.replies || []);
        setTotalReplies(repliesData.total || 0);
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchThreadAndReplies();
  }, [id, page]);

  const totalPages = Math.ceil(totalReplies / limit);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!replyContent.trim()) return;
  
    setStatus("submitting");
  
    try {
      const res = await fetch(`/api/forums/threads/${id}/replies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({ content: replyContent }),
      });
  
      if (res.ok) {
        setReplyContent("");
        setStatus("idle");
  
        // ✅ Re-fetch replies for current page
        const refreshed = await fetch(`/api/forums/threads/${id}/replies?page=${page}&limit=${limit}`).then((r) =>
          r.json()
        );
        setReplies(refreshed.replies || []);
        setTotalReplies(refreshed.total || 0);
      } else {
        setStatus("error");
      }
    } catch {
      setStatus("error");
    }
  };  

  const handleEdit = async (replyId: number) => {
    const res = await fetch(`/api/forums/replies/${replyId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify({ content: editedContent }),
    });

    if (res.ok) {
      setReplies((prev) =>
        prev.map((r) =>
          r.id === replyId ? { ...r, content: editedContent } : r
        )
      );
      setEditingReplyId(null);
    } else {
      alert("Failed to update reply.");
    }
  };

  const handleDelete = async (replyId: number) => {
    if (!confirm("Are you sure you want to delete this reply?")) return;

    const res = await fetch(`/api/forums/replies/${replyId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    if (res.ok) {
      setReplies((prev) => prev.filter((r) => r.id !== replyId));
      setTotalReplies((prev) => prev - 1);
    } else {
      alert("Failed to delete reply.");
    }
  };

  return (
    <PageLayout fullWidth>
      <div className="max-w-3xl mx-auto py-16 px-4">
        {loading ? (
          <p className="text-center text-gray-400">Loading thread...</p>
        ) : error ? (
          <div className="text-center text-red-400">
            <p>❌ {error}</p>
            <button
              onClick={() => navigate("/forums")}
              className="mt-4 bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-500 transition"
            >
              Back to Forums
            </button>
          </div>
        ) : thread ? (
          <>
            <h1 className="text-3xl font-bold text-purple-400 mb-4">{thread.title}</h1>
            <p className="text-sm text-gray-500 mb-6">
              Posted by <span className="text-white">{thread.username}</span> on{" "}
              {new Date(thread.created_at).toLocaleString()}
            </p>
            <div className="bg-[#1e1e22] text-gray-300 p-6 rounded-lg border border-gray-700 whitespace-pre-wrap mb-10">
              {thread.content}
            </div>

            <h2 className="text-2xl font-semibold text-purple-300 mb-4">Replies</h2>

            {replies.length === 0 ? (
              <p className="text-gray-500 mb-6">No replies yet.</p>
            ) : (
              <>
                <div className="space-y-4 mb-10">
                  {replies.map((reply) => {
                    const isAuthor = user?.username === reply.username;
                    const isEditing = editingReplyId === reply.id;

                    return (
                      <div
                        key={reply.id}
                        className="bg-[#1e1e22] border border-gray-700 p-4 rounded-lg"
                      >
                        <p className="text-sm text-gray-400 mb-1">
                          <span className="text-white font-medium">{reply.username}</span> ·{" "}
                          {new Date(reply.created_at).toLocaleString()}
                        </p>

                        {isEditing ? (
                          <>
                            <textarea
                              className="w-full bg-[#121217] text-white border border-gray-700 rounded p-2 text-sm"
                              rows={4}
                              value={editedContent}
                              onChange={(e) => setEditedContent(e.target.value)}
                            />
                            <div className="flex gap-2 mt-2">
                              <button
                                onClick={() => handleEdit(reply.id)}
                                className="px-3 py-1 bg-green-600 text-white text-sm rounded hover:bg-green-500 transition"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => setEditingReplyId(null)}
                                className="px-3 py-1 bg-gray-700 text-white text-sm rounded hover:bg-gray-600 transition"
                              >
                                Cancel
                              </button>
                            </div>
                          </>
                        ) : (
                          <p className="text-gray-300 whitespace-pre-wrap">{reply.content}</p>
                        )}

                        {isAuthor && !isEditing && (
                          <div className="flex gap-4 text-sm mt-2">
                            <button
                              onClick={() => {
                                setEditingReplyId(reply.id);
                                setEditedContent(reply.content);
                              }}
                              className="text-yellow-400 hover:underline"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(reply.id)}
                              className="text-red-500 hover:underline"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                {totalPages > 1 && (
                  <div className="flex justify-center gap-4 mt-4 mb-8">
                    <button
                      onClick={() => setPage((p) => Math.max(p - 1, 1))}
                      disabled={page === 1}
                      className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-40"
                    >
                      Previous
                    </button>
                    <span className="text-gray-400 pt-2">
                      Page {page} of {totalPages}
                    </span>
                    <button
                      onClick={() => setPage((p) => Math.min(p + 1, totalPages))}
                      disabled={page === totalPages}
                      className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-40"
                    >
                      Next
                    </button>
                  </div>
                )}
              </>
            )}

            {user ? (
              <>
                <h3 className="text-xl font-semibold text-purple-400 mb-2">Add a Reply</h3>
                {status === "error" && (
                  <div className="bg-red-700 text-red-100 text-sm px-4 py-3 rounded mb-4">
                    ❌ Something went wrong. Try again.
                  </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-4 mb-10">
                  <textarea
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    required
                    rows={5}
                    className="w-full rounded bg-[#1e1e22] border border-gray-700 p-3 text-white"
                    placeholder="Write your reply..."
                  />
                  <button
                    type="submit"
                    disabled={status === "submitting"}
                    className="bg-purple-600 text-white px-6 py-2 rounded hover:bg-purple-500 transition disabled:opacity-50"
                  >
                    {status === "submitting" ? "Posting..." : "Post Reply"}
                  </button>
                </form>
              </>
            ) : (
              <p className="text-center text-gray-500">
                ⚠ You must be logged in to reply to this thread.
              </p>
            )}
          </>
        ) : null}
      </div>
    </PageLayout>
  );
};

export default ThreadPage;
